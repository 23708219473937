import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { ApiResponse, ApiStatus } from "../api/ApiUtilities";
import { getUser } from "../api/AuthApi";
import { getMeetingStatus, UnauthorisedError } from "../api/MeetingApi";
import { GetHasSessionInPersonValueBeenSet, SetSessionInPersonValue } from "../api/Session";
import { getStatusMeetingStatus } from "../api/StatusApi";
import { updateMyDetails } from "../api/UserActions";
import { getCookieValue } from "../api/Utilities";
import ContainerCard from "../common/CardContainer";
import ZoomDetails from "../components/dashboard/ZoomDetails";
import { MeetingStatus } from "../data/MeetingStatus";
import { User } from "../data/User";
import { logoutAction, myUserUpdatedAction, updateMeetingStatusAction } from "../state/actions";
import { SLinkContext } from "../state/context";
//import PropTypes from "prop-types";
//ParticipantDashboardView.propTypes = {};
import "./Resources.css";

function ParticipantDashboardView(props: any) {
  const { state, dispatch } = useContext(SLinkContext);
  const [apiUser, setApiUser] = useState<User | null>();
  const [apiError, setApiError] = useState<string>("");
  const [meetingStatus, setMeetingStatus] = useState<MeetingStatus | null>(null);
  const [setIsInPerson, setSetIsInPerson] = useState(true);

  // TODO: move this useEffect to logged in layout so it runs once on login and then only as required by different screens
  useEffect(() => {
    getMeetingStatus()
      .then((status) => {
        dispatch(updateMeetingStatusAction(status));
      })
      .catch((error: Error) => {
        if (error instanceof UnauthorisedError) {
          dispatch(logoutAction());
        }
      });
  }, []);

  useEffect(() => {
    console.log("State session ask if is in person: " + state.meetingStatus?.askIsInPerson + " - has been set: " + GetHasSessionInPersonValueBeenSet());
    if (state.meetingStatus?.askIsInPerson && !GetHasSessionInPersonValueBeenSet()) {
      setSetIsInPerson(false);
    }
  }, [state.meetingStatus, state.meetingStatus?.askIsInPerson]);

  /*function shouldAskForInPersonOrNot() {
    if (state.meetingStatus === null) {
      return false; // don't ask if there is no meeting in progress
    }
    if (state.meetingStatus.askIsInPerson === false) {
      return false; // don't ask if the session indicates not to ask
    }
    if (GetHasSessionInPersonValueBeenSet()) {
      return false; // don't ask if already asked
    }
    console.log("NO HAS SESSION VALUE BEEN SET ***************************");
    return true;
  }
  useEffect(() => {
    shouldAskForInPersonOrNot();
  }, [state.meetingStatus, state.meetingStatus?.askIsInPerson]);
  */

  function handleCloseSetIsInPerson() {
    handleCloseIsInPersonDialog(true);
  }
  function handleCloseIsInPersonDialog(isInPerson: boolean) {
    SetSessionInPersonValue(isInPerson);
    // call update to user here with setting value if not already set that way
    if (state.user?.isInPerson !== isInPerson) {
      // call api to update
      var userModel = {
        ...state.user,
        isInPerson: isInPerson,
      } as User;
      updateMyDetails(userModel)
        .then((response: ApiResponse<User>) => {
          if (response.status === ApiStatus.Ok && response.data != null) {
            dispatch(myUserUpdatedAction(response.data));
          } else if (response.status === ApiStatus.Unauthorised) {
            dispatch(logoutAction());
          }
          toast.success("Updated successfully");
        })
        .catch((error: Error) => {
          toast.error(`Error updating your is in person preference. Details: ${error.message}`);
        });
    }
    setSetIsInPerson(true);
  }
  function handleCloseSetNotIsInPerson() {
    handleCloseIsInPersonDialog(false);
  }

  return (
    <div>
      <ContainerCard heading="Welcome">
        <div className="container mt-2 px-1 pe-1">
          <p>You are signed-in. Welcome to the Diocese of Melbourne Synod website. </p>
          <p>
            A video tutorial of how to navigate this site is available{" "}
            <a href="https://www.youtube.com/watch?v=ThVMmMOC9V0" rel="noreferrer" target="_blank">
              here
            </a>
            .
          </p>
          <p>You may need to click the video to make it play the first time if the video does not auto play on your device.</p>
          <p>During Synod, a tech helpdesk will be available by phoning 9653 4282.</p>
          <p>
            For all matters not pertinent to the active item of business, please contact <a href="mailto:synod@melbourneanglican.org.au">synod@melbourneanglican.org.au</a>
          </p>
        </div>
      </ContainerCard>
      <ContainerCard heading="Synod Papers">
        <div className="container">
          <ul>
            {/* <li>No notice papers available</li> */}
            <li>
              <a className="btn btn-link" href="https://adom.synod.link/resources/Daily+Notice+Paper+12+October+2024.pdf" target="_blank" rel="noreferrer">
                Daily Notice Paper - 12th October 2024
              </a>
            </li>
          </ul>
        </div>
      </ContainerCard>
      {/*
      <ContainerCard heading="Synod Papers">
        <div className="container">
          <ul>
            <li>
              <a className="btn btn-link" href="https://adom.synod.link/resources/DailyNoticePaperSaturday14thOctober2023Attachments.pdf" target="_blank" rel="noreferrer">
                Daily Notice Paper - Saturday 14th October
              </a>
            </li>
            <li hidden>
              <a className="btn-link" href="https://adom.synod.link/resources/adom2022/Synod2022-DailyNoticePaper-Saturday15thOctober.pdf" target="_blank" rel="noreferrer">
                Daily Notice Paper – Saturday 15th October
              </a>
            </li>
            <li hidden>
              <a className="btn-link" href="https://adom.synod.link/resources/Synod2022_PartA_OrderofBusiness_Legislation_FINALv2.pdf" target="_blank" rel="noreferrer">
                Synod Papers Part A
              </a>
            </li>
            <li hidden>
              <a className="btn-link" href="https://adom.synod.link/resources/BookB_Synod2022_FINALcompressedv2.pdf" target="_blank" rel="noreferrer">
                Synod Papers Part B
              </a>
            </li>
            <li hidden>
              For other Synod information including newsletters, log on to the{" "}
              <a className="btn-link" href="https://parishportal.melbourneanglican.org.au/parish-tools/Melbourne-Diocesan-Synod-2022/Pages/Melbourne-Synod-2022.aspx" target="_blank" rel="noreferrer">
                Synod 2022 page
              </a>{" "}
              of the Parish Portal.
            </li>
          </ul>
        </div>
      </ContainerCard>
      <ContainerCard heading="Leading your Church into Growth - Video Resources">
        <div className="row" id="resources">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="col">
              <a className="btn btn-success" href="https://synod.link/resources/Introduction-JSanderson.mp4" target="_blank" rel="noreferrer">
                Introduction
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 order-1">
            <div className="col">
              <a className="btn btn-success" href="https://synod.link/resources/Geelong.mp4" target="_blank" rel="noreferrer">
                St Stephen's Anglican Church - Belmont, Geelong
              </a>
              <a className="btn btn-success" href="https://synod.link/resources/Moorabin.mp4" target="_blank" rel="noreferrer">
                St David's Anglican Church - Moorabin
              </a>
              <a className="btn btn-success" href="https://synod.link/resources/SurfcoastAnglicanParish.mp4" target="_blank" rel="noreferrer">
                Surfcoast Anglican Parish
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 order-2">
            <div className="col">
              <a className="btn btn-success" href="https://synod.link/resources/GlenroyMerlynstone.mp4" target="_blank" rel="noreferrer">
                St Matthew's - Glenroy & St Linus' - Merlynstone
              </a>
              <a className="btn btn-success" href="https://synod.link/resources/LancefieldRomsey.mp4" target="_blank" rel="noreferrer">
                Lancefield & Romsey
              </a>
              <a className="btn btn-success" href="https://synod.link/resources/InverleighBannockburnMeredith.mp4" target="_blank" rel="noreferrer">
                Inverleigh, Bannockburn & Meredith Parishes
              </a>
            </div>
          </div>
        </div>
      </ContainerCard>
      */}
      <ContainerCard heading="Virtual Display Tables">
        <div className="row" id="resources">
          {/* Anglican Funds */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="row">
              <h2 className="text-center">Anglican Development Fund and Anglican Funds</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="http://www.anglicanfunds.au" target="_blank" rel="noreferrer">
                  <img className="image" src="https://adom.synod.link/resources/synod2024/AnglicanFunds.png" width="250" height="48" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://www.melbourneanglican.org.au/agencies-anglican-development-fund/" target="_blank" rel="noreferrer">
                  Anglican Development Funds website
                </a>
                <a className="btn btn-success" href="https://www.melbourneanglican.org.au/agencies/anglican-funds-melbourne/" target="_blank" rel="noreferrer">
                  Anglican Funds website
                </a>
              </div>
            </div>
          </div>

          {/* Anglican Board of Mission */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="row">
              <h2 className="text-center">Anglican Board of Mission</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="http://www.abmission.org/" target="_blank" rel="noreferrer">
                  <img id="abm" src="https://adom.synod.link/resources/adom2022/abm-logo-round-white-bg-rgb-500.png" width="250" height="119" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://www.youtube.com/watch?v=Ef_B6qSfAoM&t=36s" target="_blank" rel="noreferrer">
                  The Anglican Board of Mission and Anglicans In Development Video
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/synod2024/abm/ABM%20AID%20Project%20book%202024.pdf" target="_blank" rel="noreferrer">
                  Current Project Book
                </a>
                <a className="btn btn-success" href="http://www.abmission.org/" target="_blank" rel="noreferrer">
                  Website
                </a>
              </div>
            </div>
          </div>

          {/* Bishops Court */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="row">
              <h2 className="text-center">Bishops Court</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="https://www.anglicarevic.org.au/" target="_blank" rel="noreferrer">
                  <img id="abm" src="https://adom.synod.link/resources/BishopsCourt.png" width="250" height="82" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://adom.synod.link/resources/synod2024/bishopscourt/ADoM%20Bishopscourt%20fundraise%20booklet.pdf" target="_blank" rel="noreferrer">
                  Bishopscourt Fundraise Booklet
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/synod2024/bishopscourt/ADoM%20Bishopscourt%20donation%20guide.pdf" target="_blank" rel="noreferrer">
                  Donation Guide
                </a>
              </div>
            </div>
          </div>

          {/* Brotherhood of St Laurence */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="row">
              <h2 className="text-center">Brotherhood of St Laurence</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="http://www.bsl.org.au/" target="_blank" rel="noreferrer">
                  <img className="image" src="https://adom.synod.link/resources/BrotherhoodStLaurence.png" width="250" height="55" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://www.youtube.com/watch?v=5nVhWVHntYM&feature=youtu.be" target="_blank" rel="noreferrer">
                  Annual Report Video
                </a>
                <a className="btn btn-success" href="https://youtu.be/76p94NWCAtQ" target="_blank" rel="noreferrer">
                  Lived Experience Video
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/synod2024/bsl/BSL_Annual-Report-2024_e.pdf" target="_blank" rel="noreferrer">
                  Annual Report
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/synod2024/bsl/BSL_General_DL-6panel_Feb2024.pdf" target="_blank" rel="noreferrer">
                  BSL Brochure
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/synod2024/bsl/BowmanHarrison_Inequality-In-Australia_2024.pdf" target="_blank" rel="noreferrer">
                  Life chances report
                </a>
                <a className="btn btn-success" href="http://www.bsl.org.au/" target="_blank" rel="noreferrer">
                  Website
                </a>
              </div>
            </div>
          </div>

          {/* Bush Church Aid */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="row">
              <h2 className="text-center">Bush Church Aid</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="https://www.bushchurchaid.com.au/" target="_blank" rel="noreferrer">
                  <img className="image" src="https://www.bushchurchaid.com.au/images/logo-dark.png" width="250" height="102" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://www.bushchurchaid.com.au/" target="_blank" rel="noreferrer">
                  Website
                </a>
              </div>
            </div>
          </div>

          {/* Church Missionary Society */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="row">
              <h2 className="text-center">Church Mission Society</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="https://www.cms.org.au/" target="_blank" rel="noreferrer">
                  <img className="image" src="https://adom.synod.link/resources/CmsLogo.png" width="250" height="76" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://vimeo.com/908887050" target="_blank" rel="noreferrer">
                  This is CMS Video
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/synod2024/cms/CMS%20General%20Pamphlet%20Digital.pdf" target="_blank" rel="noreferrer">
                  General Pamphlet
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/synod2024/cms/Across%20Cultures%2025-26%20Program.pdf" target="_blank" rel="noreferrer">
                  Accross Cultures flyer
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/synod2024/cms/SUTS%202025%20launch%20A6%20flyer.pdf" target="_blank" rel="noreferrer">
                  SUTS 2025 flyer
                </a>
                <a className="btn btn-success" href="http://cms.org.au/vic" target="_blank" rel="noreferrer">
                  Website
                </a>
              </div>
            </div>
          </div>

          {/* Kooyoora */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="row">
              <h2 className="text-center">Kooyoora</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="https://www.kooyoora.org.au/ " target="_blank" rel="noreferrer">
                  <img className="image" src="https://adom.synod.link/resources/Kooyoora.png" width="250" height="102" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://adom.synod.link/resources/synod2024/kooyoora/Kooyoora%2BAnnual%2BReport%2B2023.pdf" target="_blank" rel="noreferrer">
                  Annual Report
                </a>
                <a className="btn btn-success" href="https://www.kooyoora.org.au/" target="_blank" rel="noreferrer">
                  Website
                </a>
              </div>
            </div>
          </div>

          {/* St Peters Book */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="row">
              <h2 className="text-center">St Peter's Book Room</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="http://www.bookroom.stpeters.org.au" target="_blank" rel="noreferrer">
                  <img className="image" src="https://adom.synod.link/resources/StPetersBookroom.png" width="158" height="158" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://www.bookroom.stpeters.org.au/" target="_blank" rel="noreferrer">
                  Website
                </a>
              </div>
            </div>
          </div>

          {/* ADOM Safe Ministry */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="row">
              <h2 className="text-center">Safe Ministry / Disability Access and Inclusion</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="https://www.melbourneanglican.org.au/safe-ministry/" target="_blank" rel="noreferrer">
                  <img className="image" src="https://adom.synod.link/resources/ADOMlogo.jpg" width="250" height="141" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://vimeo.com/950638567/c80be79d16?share=copy" target="_blank" rel="noreferrer">
                  Safe Ministry 2024 video
                </a>
                <a
                  className="btn btn-success"
                  href="https://adom.synod.link/resources/synod2024/safeministry/Kooyoora%20Poster%20A3%20FA%20HR%20PRINT%20-%20June%202024.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Kooyoora Poster
                </a>
                <a
                  className="btn btn-success"
                  href="https://melbourneanglican.org.au/wp-content/uploads/2023/08/ADOM-Action-Plan-for-Meeting-the-New-Victorian-Child-Safe-Standards-April-2023-updated-Aug-2023-1.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  ADOM Action Plan for Meeting the New Victorian Child Safe Standards
                </a>
                <a
                  className="btn btn-success"
                  href="https://adom.synod.link/resources/synod2024/safeministry/Child-Abuse-Risk-Management-Assessment-tool-2024-editing-restricted-with-white-text-updated-11042024.docx"
                  target="_blank"
                  rel="noreferrer"
                >
                  ADOM Child Abuse Risk Management Tool
                </a>
                <a
                  className="btn btn-success"
                  href="https://www.melbourneanglican.org.au/wp-content/uploads/2024/06/ADOM-Code-of-Conduct-for-Child-Safety-and-Wellbeing-2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Code of Conduct for Child Safety and Wellbeing (updated April 2023)
                </a>
                <a className="btn btn-success" href="https://www.melbourneanglican.org.au/safe-ministry/" target="_blank" rel="noreferrer">
                  Website
                </a>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-6 col-md-6 col-sm-12 order-1">
            <div className="row">
              <h2 className="text-center">Anglican Development Fund</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="https://www.melbourneanglican.org.au/agencies-anglican-development-fund/" target="_blank" rel="noreferrer">
                  <img className="image" src="https://www.melbourneanglican.org.au/wp-content/uploads/2019/10/ADF-logo-final-768x256.jpg" width="400" height="133" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://www.melbourneanglican.org.au/agencies-anglican-development-fund/" target="_blank" rel="noreferrer">
                  Website
                </a>
              </div>
            </div>
          </div> */}

          {/* <div className="col-lg-6 col-md-6 col-sm-12 order-4">
            <div className="row">
              <h2 className="text-center">Anglican Overseas Aid</h2>
            </div>
            <div className="row">
              <div className="col">
                <a href="https://anglicanoverseasaid.org.au/" target="_blank" rel="noreferrer">
                  <img id="abm" src="https://adom.synod.link/resources/adom2022/AOA_logo_horiz_taglineRGB300.png" width="400px" height="70px" />
                </a>
              </div>
              <div className="col">
                <a className="btn btn-success" href="https://anglicanoverseasaid.org.au/" target="_blank" rel="noreferrer">
                  Website
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/AnglicanOverseasAid_DISPLAYTABLE.docx" target="_blank" rel="noreferrer">
                  Display Table
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/AOA_AbundanceWinterWeb2022WebVersion.pdf" target="_blank" rel="noreferrer">
                  Abundance Winter 2022
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/AOA_Annual-Report-2021-Web-Version.pdf" target="_blank" rel="noreferrer">
                  Annual Report 2021
                </a>
              </div>
            </div>
          </div> */}

          {/* <div className="col-lg-6 col-md-6 col-sm-12 order-5">
            <div className="row">
              <h2 className="text-center">Australian Anglican Church Calendar</h2>
            </div>
            <div className="row">
              <div className="col">
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/AustralianAnglicanChurchCalendar_DISPLAYTABLE.docx" target="_blank" rel="noreferrer">
                  Display Table
                </a>
                <a className="btn btn-success" href="https://www.youtube.com/watch?v=wgUFvgIledk" target="_blank" rel="noreferrer">
                  Australian Anglican Church Calendar
                </a>
                <a className="btn btn-success" href="https://adom.synod.link/resources/adom2022/AustralianAnglicanChurchCalendarAdvertisement.pdf" target="_blank" rel="noreferrer">
                  Australian Anglican Church Calendar Advertisement
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </ContainerCard>
      {/* {state.user ? <ZoomDetails user={state.user} /> : <></>} */}
      {/* <div className="container">
        <h1>Participant Dashboard</h1>
        <p>
          username: {state.user?.username}
          <br />
          loggedin: {state.user ? "True" : "False"}
        </p>
        <p>+ 
          <button onClick={buttonClicked} type="button">
            Get User
          </button>
          <br />
          User: {JSON.stringify(apiUser)}
          <br />
          <button onClick={getMeetingStatus} type="button">
            Query Meeting Status S3
          </button>
          <br />
          Meeting Status: {JSON.stringify(meetingStatus)}
          <br />
          Error:
          <br /> {apiError}
        </p>
        <p>{document.cookie}</p>
        <p>{getCookieValue("SLinkPC")}</p>
      </div> */}
      <Modal show={!setIsInPerson} onHide={handleCloseSetIsInPerson}>
        <Modal.Header>
          <Modal.Title>Attending in Person?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you attending the meeting in person today?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSetNotIsInPerson}>
            No
          </Button>
          <Button variant="primary" onClick={handleCloseSetIsInPerson}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ParticipantDashboardView;
